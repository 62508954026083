import { kesproNetBaseUrl } from './config.js';
import { commonContactFormPageUrls, contactListPageUrls, foodservicePageUrls, contactForMediaPageUrls, contactForSuppliersPageUrls, salesContactListPageUrls, customerServiceListPageUrls, wholesaleListPageUrls, } from '../common/urlPaths.js';
import { SalesArea } from '../common/apiTypes.js';
export const becomeClientPageUrls = {
    fi: '/tukku/asiakkaaksi',
    en: '/en/become-customer',
    sv: '/sv/bli-kund',
    ru: '/tukku/asiakkaaksi',
};
export const languageBasePathRedirectTargetUrls = Object.assign(Object.assign({}, foodservicePageUrls), { fi: '/' });
export const navigationItems = {
    primary: {
        fi: [
            {
                to: '/tuotteet', caption: 'Tuotteet', subItems: [
                    { to: '/tuotteet', caption: 'Kaikki tuotteet', onlyActiveOnIndex: true },
                    { to: '/tuotteet/uutuudet', caption: 'Uutuudet' },
                    { to: '/tuotteet/tuotemerkit', caption: 'Tuotemerkit' },
                    { to: '/tuotteet/vain-kesprosta', caption: 'Vain Kesprosta' },
                    { to: '/tuotteet/pientuottajat', caption: 'Pientuottajat' },
                    { to: '/tuotteet/tuoretukku', caption: 'Tuoretukku' },
                    { to: '/tuotteet/astiatukku', caption: 'Astiatukku' },
                    { to: '/tuotteet/juomatukku', caption: 'Juomatukku' },
                    { to: '/tuotteet/yleista', caption: 'Yleistä' },
                ],
            },
            {
                to: '/palvelut', caption: 'Palvelut', subItems: [
                    { to: '/palvelut', caption: 'Kaikki palvelut', onlyActiveOnIndex: true },
                    { to: '/palvelut/digipalvelut', caption: 'Digitaaliset palvelut' },
                    { to: '/palvelut/ravintolatukku', caption: 'Ravintolatukku' },
                    { to: '/palvelut/foodsteri', caption: 'Foodsteri' },
                    { to: '/palvelut/logistiikkapalvelu', caption: 'Logistiikkapalvelu' },
                    { to: '/palvelut/kumppanipalvelut', caption: 'Kumppanipalvelut' },
                    { to: '/palvelut/koulutukset', caption: 'Koulutukset' },
                ],
            },
            {
                to: '/ideat-ja-inspiraatiot',
                caption: 'Ideat ja inspiraatiot',
                hideDesktopMenuBar: true,
                subItems: [
                    { to: '/ideat-ja-inspiraatiot', caption: 'Artikkelit ja reseptit' },
                    { to: '/sesongissa', caption: 'Sesongissa' },
                    { to: '/ravintolailmiot', caption: 'Ravintolailmiöt' },
                    { to: '/ratkaisut', caption: 'Ratkaisut' },
                    { to: '/bocusedor', caption: `Bocuse d'Or` },
                ],
            },
            { to: '/tuotekuvastot-ja-lehdet', caption: 'Julkaisut' },
            { to: '/tapahtumat', caption: 'Tapahtumat' },
        ],
        en: [
            { to: foodservicePageUrls.en, caption: 'Kespro in brief' },
            { to: '/en/services', caption: 'Services' },
            { to: '/en/events', caption: 'Events' },
        ],
        sv: [
            { to: foodservicePageUrls.sv, caption: 'Kort om Kespro' },
        ],
        ru: [
            { to: foodservicePageUrls.ru, caption: 'кеспро по-русски' },
        ],
    },
    secondary: {
        fi: [
            { to: foodservicePageUrls.fi, caption: 'Yritys' },
            { to: wholesaleListPageUrls.fi, caption: 'Noutotukut' },
            {
                to: contactListPageUrls.fi, caption: 'Yhteystiedot', subItems: [
                    { to: contactListPageUrls.fi, caption: 'Kaikki yhteystiedot', onlyActiveOnIndex: true },
                    { to: wholesaleListPageUrls.fi, caption: 'Noutotukut' },
                    { to: customerServiceListPageUrls.fi, caption: 'Asiakaspalvelu' },
                    { to: salesContactListPageUrls.fi, caption: 'Myynti' },
                    { to: contactForSuppliersPageUrls.fi, caption: 'Tavarantoimittajille' },
                    { to: contactForMediaPageUrls.fi, caption: 'Medialle' },
                ],
            },
        ],
        en: [
            { to: wholesaleListPageUrls.en, caption: 'Wholesales' },
            { to: contactListPageUrls.en, caption: 'Contact information', subItems: [
                    { to: contactListPageUrls.en, caption: 'Contact information', onlyActiveOnIndex: true },
                    { to: wholesaleListPageUrls.en, caption: 'Wholesales' },
                    { to: customerServiceListPageUrls.en, caption: 'Customer service' },
                    { to: salesContactListPageUrls.en, caption: 'Sales' },
                    { to: contactForSuppliersPageUrls.en, caption: 'Information for suppliers' },
                    { to: contactForMediaPageUrls.en, caption: 'Media contact' },
                ],
            },
        ],
        sv: [
            { to: wholesaleListPageUrls.sv, caption: 'Grossistbutiker' },
            { to: contactListPageUrls.sv, caption: 'Kontakt information', subItems: [
                    { to: contactListPageUrls.sv, caption: 'Kontakt information', onlyActiveOnIndex: true },
                    { to: wholesaleListPageUrls.sv, caption: 'Grossistbutiker' },
                    { to: customerServiceListPageUrls.sv, caption: 'Kundtjänst' },
                    { to: salesContactListPageUrls.sv, caption: 'Försäljning' },
                    { to: contactForSuppliersPageUrls.sv, caption: 'Information till leverantörer' },
                    { to: contactForMediaPageUrls.sv, caption: 'Media kontakt' },
                ],
            },
        ],
        ru: [
            { to: contactListPageUrls.ru, caption: 'Contact information' },
        ],
    },
    buttons: {
        fi: {
            goToWebStore: { to: `${kesproNetBaseUrl}/`, caption: 'Siirry Kespronetiin' },
            goToWebStoreAuthenticated: { to: `${kesproNetBaseUrl}/kirjaudu/ksso`, caption: 'Siirry Kespronetiin' },
            becomeClient: { to: '/tukku/asiakkaaksi', caption: 'Tule asiakkaaksi' },
            search: { to: '/haku', caption: 'Hae' },
            mobileContactUs: { to: commonContactFormPageUrls.fi, caption: 'Ota yhteyttä' },
            digitalServices: { to: '', caption: 'Digitaaliset palvelut' },
            logIn: { to: '/kirjaudu/ksso?language=fi', caption: 'Kirjaudu' },
        },
        en: {
            goToWebStore: { to: `${kesproNetBaseUrl}/`, caption: 'Go to \n webstore' },
            goToWebStoreAuthenticated: { to: `${kesproNetBaseUrl}/kirjaudu/ksso`, caption: 'Go to \n webstore' },
            becomeClient: { to: '/en/become-customer', caption: 'Become a \n customer' },
            search: { to: '/haku', caption: 'Search \n (in Finnish)' },
            mobileContactUs: { to: '/en/become-customer#contact-us', caption: 'Contact us' },
            digitalServices: { to: '', caption: 'Digital services' },
            logIn: { to: '/kirjaudu/ksso?language=en', caption: 'Login' },
        },
        sv: {
            goToWebStore: { to: `${kesproNetBaseUrl}/`, caption: 'Till \n webbutiken' },
            goToWebStoreAuthenticated: { to: `${kesproNetBaseUrl}/kirjaudu/ksso`, caption: 'Till \n webbutiken' },
            becomeClient: { to: '/sv/bli-kund', caption: 'Bli kund' },
            search: { to: '/haku', caption: 'Sök \n (på Finska)' },
            mobileContactUs: { to: '/sv/bli-kund#kontakt', caption: 'Kontakta oss' },
            digitalServices: { to: '', caption: 'Digitala tjänster' },
            logIn: { to: '/kirjaudu/ksso?language=sv', caption: 'Logga in' },
        },
        ru: {
            goToWebStore: { to: `${kesproNetBaseUrl}/`, caption: 'Siirry Kespronetiin' },
            goToWebStoreAuthenticated: { to: `${kesproNetBaseUrl}/kirjaudu/ksso`, caption: 'Siirry Kespronetiin' },
            becomeClient: { to: '/tukku/asiakkaaksi', caption: 'Tule asiakkaaksi' },
            search: { to: '/haku/tuotteet', caption: 'Hae' },
            mobileContactUs: { to: commonContactFormPageUrls.ru, caption: 'Ota yhteyttä' },
            digitalServices: { to: '', caption: 'Digitaaliset palvelut' },
            logIn: { to: '/kirjaudu/ksso?language=ru', caption: 'Kirjaudu' },
        },
    },
};
export const quickLinks = {
    fi: [
        { to: 'https://www.oivahymy.fi/hae-yrityksia/#text=kespro&/', caption: 'Oiva-raportit', blank: true, external: true },
        { to: commonContactFormPageUrls.fi, caption: 'Ota yhteyttä', blank: false, hashLink: true },
        { to: foodservicePageUrls.fi, caption: 'Tukku', blank: false },
        { to: '/tukku/asiakkaaksi#tule-asiakkaaksi', caption: 'Haluan asiakkaaksi', blank: false, hashLink: true },
        { to: '/laskut', caption: 'Laskut ja maksut', blank: false },
        { to: commonContactFormPageUrls.fi, caption: 'Anna palautetta', blank: false, external: false, hashLink: true },
        { to: '/usein-kysyttya', caption: 'Usein kysyttyä', blank: false },
        { to: '/kayttoturvatiedotteet', caption: 'Käyttöturvatiedotteet', blank: false },
    ],
    en: [
        { to: foodservicePageUrls.en, caption: 'Kespro in brief', blank: false },
        { to: '/en/become-customer#contact-us', caption: 'Contact us', blank: false, hashLink: true },
        { to: 'https://www.kespro.fi', caption: 'Webstore', blank: true, external: true },
    ],
    sv: [
        { to: foodservicePageUrls.sv, caption: 'Kort om Kespro', blank: false },
        { to: '/sv/bli-kund#kontakt', caption: 'Kontakta oss', blank: false, hashLink: true },
        { to: 'https://www.kespro.fi', caption: 'Webbutik', blank: true, external: true },
    ],
    ru: [
        { to: foodservicePageUrls.ru, caption: 'Kespro in brief', blank: false },
    ],
};
export const defaultLanguageItems = [
    { to: languageBasePathRedirectTargetUrls.fi, caption: 'fi', language: 'fi' },
    { to: languageBasePathRedirectTargetUrls.en, caption: 'en', language: 'en' },
    { to: languageBasePathRedirectTargetUrls.sv, caption: 'sv', language: 'sv' },
    { to: languageBasePathRedirectTargetUrls.ru, caption: 'ru', language: 'ru' },
];
export const soMeItems = [
    { to: 'https://www.facebook.com/kespro/', iconType: 'facebook' },
    { to: 'https://www.instagram.com/kesprosuomi/', iconType: 'instagram' },
    { to: 'https://www.linkedin.com/company/kespro-oy/', iconType: 'linkedin' },
    { to: 'https://www.youtube.com/channel/UCtgggvldVTw04CaxcoLRhVA', iconType: 'youtube' },
];
const abbreviationsFi = {
    monday: 'ma',
    tuesday: 'ti',
    wednesday: 'ke',
    thursday: 'to',
    friday: 'pe',
    saturday: 'la',
    sunday: 'su',
};
const abbreviationsEn = {
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
};
const abbreviationsSv = {
    monday: 'Mån',
    tuesday: 'Tis',
    wednesday: 'Ons',
    thursday: 'Tor',
    friday: 'Fre',
    saturday: 'Lör',
    sunday: 'Sön',
};
const contactSalesAreaFi = {
    [SalesArea.SouthernFinland]: 'Etelä-Suomi',
    [SalesArea.EasternFinland]: 'Itä-Suomi',
    [SalesArea.CentralFinland]: 'Keski-Suomi',
    [SalesArea.WesternFinland]: 'Länsi-Suomi',
    [SalesArea.NorthernFinland]: 'Pohjois-Suomi',
};
const contactSalesAreaEn = {
    [SalesArea.SouthernFinland]: 'Southern Finland',
    [SalesArea.EasternFinland]: 'Eastern Finland',
    [SalesArea.CentralFinland]: 'Central Finland',
    [SalesArea.WesternFinland]: 'Western Finland',
    [SalesArea.NorthernFinland]: 'Northern Finland',
};
const contactSalesAreaSv = {
    [SalesArea.SouthernFinland]: 'Södra Finland',
    [SalesArea.EasternFinland]: 'Östra Finland',
    [SalesArea.CentralFinland]: 'Inre Finland',
    [SalesArea.WesternFinland]: 'Västra Finland',
    [SalesArea.NorthernFinland]: 'Norra Finland',
};
const contactSalesTypeFi = {
    Noutotukku: 'Noutotukku',
    Erikoistukku: 'Erikoistukku',
    Astiamyynti: 'Astiamyynti',
    HoReCa: 'HoReCa',
    Julkishallinto: 'Julkishallinto',
    Jälleenmyynti: 'Jälleenmyynti',
    Ketjumyynti: 'Ketjumyynti',
    Palvelut: 'Palvelut',
};
const contactSalesTypeEn = {
    Noutotukku: 'Wholesale store',
    Erikoistukku: 'Specialty wholesale',
    Astiamyynti: 'Tableware',
    HoReCa: 'HoReCa',
    Julkishallinto: 'Public operators',
    Jälleenmyynti: 'Resales',
    Ketjumyynti: 'Restaurant chains',
    Palvelut: 'Services',
};
const contactSalesTypeSv = {
    Noutotukku: 'Grossistbutik',
    Erikoistukku: 'Specialgrossist',
    Astiamyynti: 'Kärlförsäljning',
    HoReCa: 'HoReCa',
    Julkishallinto: 'Offentlig förvaltning',
    Jälleenmyynti: 'Återförsäljning',
    Ketjumyynti: 'Restaurangkedjor',
    Palvelut: 'Tjänster',
};
const commonStringsFi = {
    headerTitleTemplate: '%s — HoReCa-tukku Kespro',
    headerDefaultTitle: 'Kespro.com - HoReCa-tukku Kespro',
    kesproContact: '<a href="mailto:kespro.asiakaspalvelu@kesko.fi">kespro.asiakaspalvelu@kesko.fi</a><br><a href="tel:+358105324300">0105324300</a>',
    registrationFormHeader: 'Yhteydenottopyyntö',
    registrationFormPhone: 'Puhelinnumero (kansainvälinen muoto)',
    registrationFormButton: 'Lähetä',
    registrationFormStatusSuccess: 'Kiitos, yhteydenottopyyntösi on vastaanotettu!',
    registrationFormSuccessRedirectPage: '/tukku/asiakkaaksi/kiitossivu',
    commonContactFormPlaceHolder: 'Kirjoita viestisi tähän',
    commonContactFormHeader: 'Haluan, että minuun otetaan yhteyttä:',
    commonContactFormButton: 'Lähetä',
    commonContactFormEmail: 'Sähköpostiosoite',
    commonContactFormPhone: 'Puhelinnumero',
    commonContactFormMessage: 'Viesti',
    commonContactFormInstructions: 'Ota yhteyttä Kespron asiakaspalveluun:<br><a href="mailto:kespro.asiakaspalvelu@kesko.fi">kespro.asiakaspalvelu@kesko.fi</a><br><a href="tel:+358105324300">0105324300</a>',
    formStatusSending: 'Lähetetään palautetta...',
    formStatusError: 'Tapahtui virhe. Yritä hetken päästä uudestaan.',
    formStatusSucces: 'Kiitos, yhteydenottopyyntösi on vastaanotettu!',
    formCaptureLoadingError: 'Lomakkeen latautumisessa tapahtui virhe, yritä myöhemmin uudestaan.',
    checkThese: 'Ennen lähettämistä, tarkista vielä nämä:',
    noThanks: 'Ei kiitos',
    byEmail: 'Sähköpostitse',
    byPhone: 'Puhelimitse',
    forename: 'Etunimi',
    surname: 'Sukunimi',
    businessName: 'Yrityksen nimi',
    businessId: 'Y-tunnus',
    businessIdPlaceholder: '1234567-1',
    locality: 'Paikkakunta',
    additionalInfo: 'Lisätietoja',
    contactUsHeader: 'Ota yhteyttä',
    feedbackAndContact: 'Palaute ja yhteydenotto',
    wantToBeCustomer: 'Haluan asiakkaaksi',
    contactUsLinkIdTag: 'ota-yhteytta',
    noUpcomingEvents: 'Ei tulevia tapahtumia',
    openingHoursClosedToday: 'Tänään suljettu',
    openingHoursOpenToday: 'Tänään auki',
    openingHoursClosed: 'suljettu',
    wholesale: 'Noutotukku',
};
const commonStringsEn = {
    headerTitleTemplate: '%s — HoReCa-wholesale Kespro',
    headerDefaultTitle: 'Kespro.com - HoReCa-wholesale Kespro',
    kesproContact: '<a href="mailto:kespro.asiakaspalvelu@kesko.fi">kespro.asiakaspalvelu@kesko.fi</a><br><a href="tel:+358105324300">+358 1053 24300</a>',
    registrationFormHeader: 'Contact us',
    registrationFormPhone: 'Phone number',
    registrationFormButton: 'Send',
    registrationFormStatusSuccess: 'Thank you, your contact form was received!',
    registrationFormSuccessRedirectPage: '/en/become-a-customer/thank-you',
    commonContactFormPlaceHolder: 'Write your message here',
    commonContactFormHeader: 'I want to be contacted:',
    commonContactFormButton: 'Submit',
    commonContactFormEmail: 'Email address',
    commonContactFormPhone: 'Phone number',
    commonContactFormMessage: 'Message',
    commonContactFormInstructions: 'In urgent matters, kindly contact customer service:<br><a href="mailto:kespro.asiakaspalvelu@kesko.fi">kespro.asiakaspalvelu@kesko.fi</a><br><a href="tel:+358105324300">0105324300</a>',
    formStatusSending: 'Sending feedback...',
    formStatusError: 'An error has happened. Please try again in a moment.',
    formStatusSucces: 'Form submitted, thank you for contacting us!',
    formCaptureLoadingError: 'Error occurred, kindly try again later.',
    checkThese: 'Kindly check before submitting:',
    noThanks: 'No Thanks',
    byEmail: 'By email',
    byPhone: 'By phone',
    forename: 'Forename',
    surname: 'Surname',
    businessName: 'Company name',
    businessId: 'Business identity code',
    businessIdPlaceholder: '1234567-1',
    locality: 'City',
    additionalInfo: 'Message',
    contactUsHeader: 'Contact us',
    feedbackAndContact: 'Feedback and contact',
    wantToBeCustomer: 'I want to be a customer',
    contactUsLinkIdTag: 'contact-us',
    noUpcomingEvents: 'No upcoming events',
    openingHoursClosedToday: 'TranslationNotFound',
    openingHoursOpenToday: 'Opening hours',
    openingHoursClosed: 'closed',
    wholesale: 'Wholesale store',
};
const commonStringsSv = Object.assign(Object.assign({}, commonStringsEn), { headerTitleTemplate: '%s — HoReCa-grossist Kespro', headerDefaultTitle: 'Kespro.com - HoReCa-grossist Kespro', registrationFormButton: 'Skicka', registrationFormPhone: 'Telefonnummer', registrationFormStatusSuccess: 'Meddelandet skickades, tack för din kontakt!', registrationFormSuccessRedirectPage: '', commonContactFormPlaceHolder: 'Skriv ditt meddelande här', commonContactFormHeader: 'Jag önskar ett svar', commonContactFormButton: 'skicka', commonContactFormEmail: 'E-postadress', commonContactFormPhone: 'Telefonnummer', commonContactFormMessage: 'Meddelande', commonContactFormInstructions: 'Vid brådskande ärenden vänligen kontakta kundservice:<br><a href="mailto:kespro.asiakaspalvelu@kesko.fi">kespro.asiakaspalvelu@kesko.fi</a><br><a href="tel:+358105324300">0105324300</a>', formStatusError: 'Ett fel uppstod. Försök igen om ett tag.', formCaptureLoadingError: 'Ett fel uppstod, vänligen försök igen senare.', checkThese: 'Kontrollera att du har fyllt i alla obligatoriska fält korrekt', noThanks: 'Inget svar', byEmail: 'via e-post', byPhone: 'via telefon', forename: 'Förnamn', surname: 'Efternamn', businessName: 'Företagets namn', businessId: 'FO-nummer', locality: 'Stad', additionalInfo: 'Meddelande', contactUsHeader: 'Kontakta oss', feedbackAndContact: 'Ge respons', wantToBeCustomer: 'Jag vill bli kund', contactUsLinkIdTag: 'kontakt', openingHoursClosedToday: 'Öppettider', openingHoursOpenToday: 'Öppet idag', openingHoursClosed: 'stängd', wholesale: 'Grossistbutik' });
const footerStringsFi = {
    linksHeader: 'Pikalinkit',
    lawTextAddress: 'Kesko Oyj / Kespro Työpajankatu 12, 00580 HELSINKI PL 1, 00016 KESKO Puh. 01053 040',
    lawLinkCookieSettings: ' Evästeasetukset ',
    lawLinkCookiePolicy: ' Evästekäytännöt ',
    lawLinkTermsOfUse: ' Käyttöehdot ',
    lawLinkPrivacyPolicy: ' Tietosuojaseloste',
    wholeSaleHeader: 'Kespron noutotukut',
    contactHeader: 'Ota yhteyttä',
    phone: '010 5324 300',
    phoneInfo: 'ma-pe klo 07.30-15.30 8,35 snt/puh + 12,09 snt/min.',
    phoneHref: 'tel:+358105324300',
    email: 'kespro.asiakaspalvelu@kesko.fi',
    emailInfo: 'Vastaamme arkipäivisin',
    emailHref: 'mailto:kespro.asiakaspalvelu@kesko.fi',
};
const footerStringsEn = {
    linksHeader: 'Quick Links',
    lawTextAddress: 'Kesko Corporation / Kespro Työpajankatu 12, 00580 HELSINKI PL 1, 00016 KESKO Phone 01053 040',
    lawLinkCookieSettings: ' Cookie settings ',
    lawLinkCookiePolicy: ' Cookie policy ',
    lawLinkTermsOfUse: ' Terms of use ',
    lawLinkPrivacyPolicy: ' Privacy policy',
    wholeSaleHeader: 'Wholesale stores',
    contactHeader: 'Contact',
    phone: '010 5324 300',
    phoneInfo: 'Mon-Fri: 07.30-15.30 8,35 snt/call + 12,09 snt/min.',
    phoneHref: 'tel:+358105324300',
    email: 'kespro.asiakaspalvelu@kesko.fi',
    emailInfo: 'Answering workdays only',
    emailHref: 'mailto:kespro.asiakaspalvelu@kesko.fi',
};
const footerStringsSv = Object.assign(Object.assign({}, footerStringsEn), { linksHeader: 'Snabblänkar', contactHeader: 'Kontakt', wholeSaleHeader: 'Våra grossistbutiker' });
export const translations = {
    fi: {
        abbreviations: abbreviationsFi,
        contactSalesArea: contactSalesAreaFi,
        contactSalesType: contactSalesTypeFi,
        common: commonStringsFi,
        footer: footerStringsFi,
    },
    en: {
        abbreviations: abbreviationsEn,
        contactSalesArea: contactSalesAreaEn,
        contactSalesType: contactSalesTypeEn,
        common: commonStringsEn,
        footer: footerStringsEn,
    },
    sv: {
        abbreviations: abbreviationsSv,
        contactSalesArea: contactSalesAreaSv,
        contactSalesType: contactSalesTypeSv,
        common: commonStringsSv,
        footer: footerStringsSv,
    },
    ru: {
        abbreviations: abbreviationsEn,
        contactSalesArea: contactSalesAreaEn,
        contactSalesType: contactSalesTypeEn,
        common: commonStringsEn,
        footer: footerStringsEn,
    },
};
export const formValidationErrors = {
    forename: {
        fi: 'Syötä etunimi',
        en: 'Enter forename',
        sv: 'Obligatoriskt förnamn',
        ru: 'Enter forename',
    },
    surname: {
        fi: 'Syötä sukunimi',
        en: 'Enter surname',
        sv: 'Obligatoriskt efternamn',
        ru: 'Enter surname',
    },
    email: {
        fi: 'Virheellinen sähköposti',
        en: 'Invalid email',
        sv: 'Felaktigt e-postadress',
        ru: 'Invalid email',
    },
    phone: {
        fi: 'Puhelinnumeron tulee olla kansainvälisessä muodossa, esim. +358501234567',
        en: 'Phone number\'s country code is required, eg. +358501234567',
        sv: 'Fyll i telefonnummer med landsnummer, t.ex. +358501234567',
        ru: 'Invalid phone number',
    },
    companyName: {
        fi: 'Syötä yrityksen nimi',
        en: 'Enter company name',
        sv: 'Obligatoriskt företagets namn',
        ru: 'Enter company name',
    },
    emptyMessage: {
        fi: 'Syötä viesti',
        en: 'Enter message',
        sv: 'Obligatoriskt meddelande',
        ru: 'Enter message',
    },
    tooLongMessage: {
        fi: 'Liian pitkä viesti',
        en: 'Too long message',
        sv: 'För långt meddelande',
        ru: 'Too long message',
    },
    businessId: {
        fi: 'Syötä yrityksesi Y-tunnus',
        en: 'Enter company ID',
        sv: 'Fyll i FO-nummer',
        ru: 'Enter company ID',
    },
    locality: {
        fi: 'Syötä paikkakunta',
        en: 'Enter locality',
        sv: 'Fyll i plats',
        ru: 'Enter locality',
    },
};
