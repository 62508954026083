import React from 'react';
import { slugifyBrand } from '@kesko/kespro-product-service-api';
import { isAvailableProductPageLanguage, } from '../../../../common/apiTypes.js';
import { getProductNameKds } from '../../../../client/meta.js';
import { ProductCardCarousel, Breadcrumb, useTranslation, NutritionalAttributeHighlights, ProductCardMarketingText, ProductDetails, ProductInfoTab, ProductNutritionTab, ProductInstructionsTab, ProductHazardsTab, SalesBatches, hasAnyProductInfoData, ProductCardSolo, AdditionalProducts, ProductCardFilesTab, hasDocuments, } from '@kesko/kespro-common-components';
import { slugify, transformBreadcrumbMap, transformSoloImages } from './utils/transformation.js';
import styles from './style.scss';
import '@kesko/kespro-common-components/dist/assets/assets/kccColors.css';
import '@kesko/kespro-common-components/dist/assets/components/ProductCardCarousel/productcardcarousel.css';
import '@kesko/kespro-common-components/dist/assets/components/Breadcrumb/breadcrumb.css';
import { ClientOutreach } from './components/index.js';
import { combineClassNames } from '../../../util/css/classNames.js';
import { LinkWithScroll } from '../../../util/scroll.js';
import { useProductCardAnalytics } from './hooks/useProductCardAnalytics.js';
export const ProductCardComponent = ({ productData, gtin, isLoading, categories, hierarchy, substitutes, productName, isLoggedIn, language, }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const breadCrumb = transformBreadcrumbMap(categories, hierarchy);
    const { substitutesData, handleGoogleAnalyticsEvent } = useProductCardAnalytics(productName, productData, substitutes);
    const validatedLang = isAvailableProductPageLanguage(language) ? language : 'fi';
    const productRecommendationComponents = (_a = substitutes.products) === null || _a === void 0 ? void 0 : _a.filter((pr) => pr && pr.ean && pr.images).map((p) => {
        var _a, _b, _c;
        const product = substitutesData === null || substitutesData === void 0 ? void 0 : substitutesData.find((item) => item.productCardData.id === p.ean);
        if (!product) {
            return null;
        }
        const slugifiedPath = `/tuotteet/${slugify(`${p.name}-${p.ean}`)}`;
        const onClickHandler = handleGoogleAnalyticsEvent(product, slugifiedPath);
        const packagingMarkings = (_b = (_a = p === null || p === void 0 ? void 0 : p.packagingMarkings) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.packaging_marked_label_accreditation_code;
        const matchingProductInBatches = (_c = productData === null || productData === void 0 ? void 0 : productData.batches) === null || _c === void 0 ? void 0 : _c.find((batch) => batch.sapuska_code === p.id);
        if (matchingProductInBatches) {
            return null;
        }
        return (React.createElement("a", { onClick: onClickHandler, key: (p === null || p === void 0 ? void 0 : p.ean) || '', href: slugifiedPath, className: styles.additionalProdLink },
            React.createElement(ProductCardSolo, { packagingMarkings: packagingMarkings, productName: p.logisticVariantName || p.name, gtin: (p === null || p === void 0 ? void 0 : p.ean) || '', images: transformSoloImages(p) })));
    });
    return (React.createElement("div", { className: styles.productCardContainer },
        React.createElement("div", { className: styles.productCardMain },
            productData && breadCrumb && (React.createElement(Breadcrumb, { ariaLabel: t('breadcrumb'), chevronColor: "var(--grey-40)", breadcrumbDestinationMap: breadCrumb })),
            React.createElement("div", { className: combineClassNames(styles.productDescription, styles.main) },
                React.createElement("h1", { "data-pw": "productCardHeader" }, (_b = getProductNameKds(productData, validatedLang)) !== null && _b !== void 0 ? _b : productName)),
            React.createElement(ProductCardCarousel, { productName: productName, gtin: gtin !== null && gtin !== void 0 ? gtin : '', imageHeight: 300, imageWidth: 700, isLoading: isLoading, images: productData === null || productData === void 0 ? void 0 : productData.assets, maxWidth: '100%' }),
            React.createElement(NutritionalAttributeHighlights, { productData: productData, language: language }),
            React.createElement(ProductCardMarketingText, { marketingText: (_d = (_c = productData === null || productData === void 0 ? void 0 : productData.names_and_descriptions) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.recommended_b2b_marketing_text }),
            React.createElement(ProductDetails, { productData: productData, brandLinkComponent: (productData === null || productData === void 0 ? void 0 : productData.brand_name) && (React.createElement(LinkWithScroll, { to: `/tuotteet/tuotemerkit/${encodeURIComponent(slugifyBrand(productData.brand_name))}` }, productData === null || productData === void 0 ? void 0 : productData.brand_name)), subBrandLinkComponent: (productData === null || productData === void 0 ? void 0 : productData.sub_brand_name) && (React.createElement(LinkWithScroll, { to: `/tuotteet/tuotemerkit/${encodeURIComponent(slugifyBrand(productData.sub_brand_name))}` }, productData === null || productData === void 0 ? void 0 : productData.sub_brand_name)), className: styles.productDetails }),
            React.createElement(ClientOutreach, { className: styles.clientOutreachMain, isLoggedIn: isLoggedIn, gtin: gtin !== null && gtin !== void 0 ? gtin : '' }),
            hasAnyProductInfoData(productData) && (React.createElement(ProductInfoTab, { productData: productData, className: styles.accordionWrapper, language: language })),
            React.createElement(ProductNutritionTab, { productData: productData, className: styles.accordionWrapper, language: language }),
            React.createElement(ProductInstructionsTab, { productData: productData, className: styles.accordionWrapper, language: language }),
            React.createElement(ProductHazardsTab, { productData: productData, className: styles.accordionWrapper, language: language }),
            productData.assets && hasDocuments(productData.assets, gtin !== null && gtin !== void 0 ? gtin : '') && (React.createElement(ProductCardFilesTab, { assetResponse: productData.assets, ean: gtin !== null && gtin !== void 0 ? gtin : '' })),
            React.createElement(SalesBatches, { batches: productData === null || productData === void 0 ? void 0 : productData.batches, className: combineClassNames(styles.batchesContainer, styles.main) }),
            productRecommendationComponents && productRecommendationComponents.length > 0 && (React.createElement(AdditionalProducts, null, productRecommendationComponents))),
        React.createElement("div", { className: styles.productCardPane },
            React.createElement("div", { className: combineClassNames(styles.productDescription, styles.pane) },
                React.createElement("h1", { "aria-hidden": true }, (_e = getProductNameKds(productData, validatedLang)) !== null && _e !== void 0 ? _e : productName),
                React.createElement(ProductDetails, { productData: productData, brandLinkComponent: (productData === null || productData === void 0 ? void 0 : productData.brand_name) && (React.createElement(LinkWithScroll, { to: `/tuotteet/tuotemerkit/${encodeURIComponent(slugifyBrand(productData.brand_name))}` }, productData === null || productData === void 0 ? void 0 : productData.brand_name)), subBrandLinkComponent: (productData === null || productData === void 0 ? void 0 : productData.sub_brand_name) && (React.createElement(LinkWithScroll, { to: `/tuotteet/tuotemerkit/${encodeURIComponent(slugifyBrand(productData.sub_brand_name))}` }, productData === null || productData === void 0 ? void 0 : productData.sub_brand_name)), className: styles.productDetails })),
            React.createElement(ClientOutreach, { className: styles.clientOutreachPane, isLoggedIn: isLoggedIn, gtin: gtin !== null && gtin !== void 0 ? gtin : '' }),
            React.createElement(SalesBatches, { batches: productData === null || productData === void 0 ? void 0 : productData.batches, className: combineClassNames(styles.batchesContainer, styles.pane) }))));
};
