// Keep the enum names updated with the translations, see contactSalesTypeEn in staticData.ts
export var SalesType;
(function (SalesType) {
    SalesType["All"] = "Kaikki";
    SalesType["Wholesale"] = "Noutotukku";
    SalesType["SpecialtyWholesale"] = "Erikoistukku";
    SalesType["HoReCa"] = "HoReCa";
    SalesType["RestaurantChains"] = "Ketjumyynti";
    SalesType["PublicOperators"] = "Julkishallinto";
    SalesType["Resales"] = "J\u00E4lleenmyynti";
    SalesType["Services"] = "Palvelut";
    SalesType["Tableware"] = "Astiamyynti";
})(SalesType || (SalesType = {}));
export var SalesArea;
(function (SalesArea) {
    SalesArea["EasternFinland"] = "It\u00E4-Suomi";
    SalesArea["SouthernFinland"] = "Etel\u00E4-Suomi";
    SalesArea["CentralFinland"] = "Keski-Suomi";
    SalesArea["WesternFinland"] = "L\u00E4nsi-Suomi";
    SalesArea["NorthernFinland"] = "Pohjois-Suomi";
})(SalesArea || (SalesArea = {}));
export function isCustomerService(ci) {
    return ci.contentType === 'customerService';
}
export function isSalesPerson(ci) {
    return ci.contentType === 'salesPerson';
}
export function isSalesPersonWithWholesaleType(ci) {
    return ci.contentType === 'salesPerson' && ci.salesType === SalesType.Wholesale;
}
export function isWholesalePerson(ci) {
    return ci.contentType === 'wholesalePerson';
}
export function isWholesale(ci) {
    return ci.contentType === 'wholesale';
}
export function isLocation(ci) {
    return ci.contentType === 'location';
}
export function toTimestamp(date) {
    return date.getTime();
}
export function toDate(timestamp) {
    return new Date(timestamp);
}
export const Locales = {
    FINNISH: 'fi-FI',
    ENGLISH: 'en',
    SWEDISH: 'sv-FI',
    RUSSIAN: 'ru-RU',
};
export const localeToLanguage = (locale) => {
    switch (locale) {
        case Locales.FINNISH:
            return 'fi';
        case Locales.ENGLISH:
            return 'en';
        case Locales.SWEDISH:
            return 'sv';
        case Locales.RUSSIAN:
            return 'ru';
        default:
            return 'fi';
    }
};
export const languageToLocale = (language) => {
    switch (language) {
        case 'fi':
            return Locales.FINNISH;
        case 'en':
            return Locales.ENGLISH;
        case 'sv':
            return Locales.SWEDISH;
        case 'ru':
            return Locales.RUSSIAN;
        default:
            return Locales.FINNISH;
    }
};
export const LegalTextSubjects = {
    PRIVACY_POLICY: 'Rekisteriseloste',
    TERMS_OF_USE: 'Käyttöehdot',
};
export var LandingPageElementType;
(function (LandingPageElementType) {
    LandingPageElementType["Row"] = "Row";
    LandingPageElementType["Column"] = "Column";
    LandingPageElementType["TextContent"] = "TextContent";
    LandingPageElementType["Hero"] = "Hero";
    LandingPageElementType["LinkCard"] = "LinkCard";
    LandingPageElementType["ContentCarousel"] = "ContentCarousel";
    LandingPageElementType["Form"] = "Form";
    LandingPageElementType["EventList"] = "EventList";
    LandingPageElementType["Event"] = "Event";
    LandingPageElementType["ABTesting"] = "ABTesting";
    LandingPageElementType["ProductList"] = "ProductList";
    LandingPageElementType["Button"] = "Button";
    LandingPageElementType["Tukkukortti"] = "Tukkukortti";
})(LandingPageElementType || (LandingPageElementType = {}));
export var FormId;
(function (FormId) {
    FormId["CONTACT"] = "yhteydenotto";
    FormId["COMMON_CONTACT"] = "yleinen-yhteydenotto";
})(FormId || (FormId = {}));
export var RowMobileDirection;
(function (RowMobileDirection) {
    RowMobileDirection["Horizontal"] = "Vierekk\u00E4in";
    RowMobileDirection["Vertical"] = "Allekkain";
})(RowMobileDirection || (RowMobileDirection = {}));
//export type ContentCarouselContentType = 'hero' | 'article';
export var ContentCarouselContentType;
(function (ContentCarouselContentType) {
    ContentCarouselContentType["HERO"] = "Hero";
    ContentCarouselContentType["ARTICLE"] = "Artikkeli";
    ContentCarouselContentType["RECIPE"] = "Resepti";
})(ContentCarouselContentType || (ContentCarouselContentType = {}));
export const availableProductPageLanguages = ['fi', 'en', 'sv'];
export const isAvailableProductPageLanguage = (lang) => availableProductPageLanguages.includes(lang);
export var LoginEventType;
(function (LoginEventType) {
    LoginEventType["Login"] = "login";
    LoginEventType["LoginRenew"] = "existing_session";
    LoginEventType["Logout"] = "logout";
})(LoginEventType || (LoginEventType = {}));
