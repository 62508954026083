import * as R from 'ramda';
import { WarehousingType, } from '@kesko/kespro-product-service-api';
import { findCategoryById, getMainCategory, } from '@kesko/kespro-ui-common';
function getCategoryIconType(categoryId, hierarchy) {
    if (categoryId === undefined) {
        return 'basket';
    }
    else {
        const iconCategory = findCategoryById(hierarchy, categoryId);
        if (iconCategory) {
            return getMainCategory(iconCategory).category.id;
        }
        else {
            return 'basket';
        }
    }
}
const memoizedGetGategoryIconType = () => {
    const iconCategoryMap = {};
    return (categoryId, hierarchy) => {
        if (categoryId === undefined) {
            return 'basket';
        }
        if (iconCategoryMap[categoryId]) {
            return iconCategoryMap[categoryId];
        }
        else {
            const iconType = getCategoryIconType(categoryId, hierarchy);
            iconCategoryMap[categoryId] = iconType;
            return iconType;
        }
    };
};
const getGategoryIconMemoized = memoizedGetGategoryIconType();
export function getProductListProduct(product, hierarchy) {
    return {
        title: product.logisticVariantName || product.name,
        id: product.ean,
        heroImageUrl: R.pathOr('', ['images', 0, 'url'], product),
        slug: product.slug || product.ean, // Slug can still be undefined, will be fixed
        category: R.pathOr('', ['categories', 2, 'name'], product),
        categoryIconType: hierarchy
            ? getGategoryIconMemoized(R.path(['categories', 2, 'id'], product), hierarchy)
            : 'basket',
        isFrozen: product.warehousingType === WarehousingType.Frozen,
    };
}
export function getCategoryLink(category) {
    return `/tuotteet?kategoria=${category.category.id}`;
}
export function getLastCategory(categories, hierarchy) {
    for (let i = categories.length - 1; i >= 0; --i) {
        const cat = categories[i];
        const category = findCategoryById(hierarchy, cat.id);
        if (!category) {
            continue;
        }
        return category;
    }
    return undefined;
}
